<template>
	<div class="main-contents code hr batch">
		<div class="tit">배치관리<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="goList()"/><img src="/images/admin/refresh_btn.png" alt="새로고침" @click="runJob()"/></div>
		<div class="search-box">
			<div class="delete_mem">{{data.jobName}} <span>{{data.lastUpdated}} / {{data.status}}<span v-if="data.status != data.exitCode">({{data.exitCode}})</span></span></div>
			<div class="label">■ Instance ID :</div><div class="detail">{{data.jobInstanceId}}</div>
			<div class="label">■ 실행 ID :</div><div class="detail">{{data.jobExecutionId}}</div><br/>
			<div class="label">■ 시작시간 :</div><div class="detail">{{data.startTime}}</div>
			<div class="label">■ 종료시간 :</div><div class="detail">{{data.endTime}}</div>
			<div class="label">■ 종료메세지 :</div><div class="detail">{{data.exitMessage|ellipsis(100)}}</div>
		</div>
		<!-- 실행 파라미터 리스트 -->
		<div class="Board type3">
			<div class="tb_name">실행 파라미터</div>
			<table class="Board_type3 admin batch">
				<colgroup>
					<col width="4%">
					<col width="6%">
					<col width="14%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>Key</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="this.paramList.length > 0">
						<tr v-for="(param, index) in this.paramList" :key="index">
							<td class="score">{{index + 1}}</td>
							<td class="score">{{param.key}}</td>
							<td class="score">{{param.value}}</td>
						</tr>
					</template>
					<template v-else>
						<!-- 관련내용 없을 경우 -->
						<tr>
							<td colspan="3" class="none">실행 파라미터가 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<!-- 결과 Context 리스트 -->
		<div class="Board type3">
			<div class="tb_name">결과 Context</div>
			<table class="Board_type3 admin batch">
				<colgroup>
					<col width="4%">
					<col width="6%">
					<col width="14%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>Key</th>
						<th>Value</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="this.contextList.length > 0">
						<tr v-for="(context, index) in this.contextList" :key="index">
							<td class="score">{{index + 1}}</td>
							<td class="score">{{context.key}}</td>
							<td class="score">{{context.value}}</td>
						</tr>
					</template>
					<template v-else>
						<!-- 관련내용 없을 경우 -->
						<tr>
							<td colspan="3" class="none">결과 Context가 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
		<!-- Step 실행 목록 리스트 -->
		<div class="Board type3 step">
			<div class="tb_name">Step 실행 목록</div>
			<table class="Board_type3 admin">
				<colgroup>
					<col width="4%">
					<col width="23%">
					<col width="15%">
					<col width="15%">
					<col width="15%">
					<col width="10%">
					<col width="10%">
				</colgroup> 
				<thead>
					<tr>
						<th>NO</th>
						<th>Step 이름</th>
						<th>시작시간</th>
						<th>종료시간</th>
						<th>상태</th>
						<th>종료메세지</th>
						<th>결과 CONTEXT</th>
					</tr>
				</thead>
				<tbody>
					<template v-if="this.stepList.length > 0">
						<tr v-for="(step, index) in this.stepList" :key="index">
							<td class="score">{{index + 1}}</td>
							<td class="score">{{step.stepName}}</td>
							<td class="score">{{step.startTime}}</td>
							<td class="score">{{step.endTime}}</td>
							<td class="score">{{step.status}}<span v-if="step.status != step.exitCode">({{step.exitCode}})</span></td>
							<td class="score">{{step.exitMessage|ellipsis(100)}}</td>
							<td class="score">
								<div v-for="(context, index) in step.contextList" :key="index">
									<span>{{context.key}}</span> : <span>{{context.value}}</span>
									<template v-if="step.contextList.length != index + 1"><br /><br /></template>
								</div>
							</td>
						</tr>
					</template>
					<template v-else>
						<!-- 관련내용 없을 경우 -->
						<tr>
							<td colspan="7" class="none">실행된 step이 없습니다!</td>
						</tr>
					</template>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			jobExecutionId : this.$route.params.jobExecutionId || '',
			data : {},
			paramList : [],
			contextList : [],
			stepList : [],
		}
	},

	mounted() {
		if(this.jobExecutionId != '') this.getBatchJob();
	},

	methods: {
		getBatchJob() {
			this.$.httpPost('/api/bat/adm/getBatchJob', {jobExecutionId : this.jobExecutionId})
				.then(res => {
					//console.log('getBatchJob RESULT', res);

					this.data = res.data;
					this.stepList = res.data.list;
					
					this.stringToJson(res.data.paramJson, this.paramList);
					this.stringToJson(res.data.shortContext, this.contextList);

				}).catch(this.$.httpErrorCommon);
		},

		stringToJson(str, ary) {
			var tempArray = JSON.parse(str.replace(/\\/, ''));

			for(var i = 0; i < Object.keys(tempArray).length; i++) {
				var key = Object.keys(tempArray)[i];
				var value = Object.values(tempArray)[i];

				var tempObj = {};
				tempObj.key = key;
				tempObj.value = value;

				ary.push(tempObj);
			}
		},

		runJob() {
			this.$.httpPost('/api/bat/adm/runJob')
				.then(res => {
					if(res) this.$router.go(-1);
				}).catch(this.$.httpErrorCommon);
		},

		goList() {
			this.$router.go(-1);
		}
	}
}
</script>